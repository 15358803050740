<template>
  <div class="card post">
    <div class="post-top">
      <router-link :to="{ name: 'user_profile', params: { username: post.user.username } }">
        <profile-image :user="post.user" />
      </router-link>

      <div class="name-timestamp-and-audience">
        <router-link :to="{ name: 'user_profile', params: { username: post.user.username } }">
          {{ post.user.first_name }} {{ post.user.last_name }}
        </router-link>

        <div class="timestamp-and-audience">
          <router-link
            v-tooltip="timestampDisplay(post.created)"
            :to="timestampLink()"
          >
            <clock-icon size="12" />

            <span :key="`created${timestampKeySuffix}`">
              {{ timestampDistanceDisplay(post.created) }}
            </span>
          </router-link>

          <span
            v-if="
              postType === 'user'
                && loggedIn
                && userData.username === post.user.username
                && post.audience !== undefined
            "
          >
            &nbsp;<span aria-hidden="true">&bull;</span>&nbsp;
            Audience: {{ audienceMap[post.audience] }}
          </span>
        </div>
      </div>
    </div>

    <div v-html="postHtml" />

    <attachments
      v-if="post.attachment_set.length"
      :attachments="post.attachment_set"
    />

    <div class="below-post-body">
      <like :post="post" />

      <div class="buttons">
        <button
          v-tooltip="'Toggle comments'"
          type="button"
          class="btn-transparent"
          @click="commentsVisible = !commentsVisible"
        >
          {{ pluralize(comments.length, 'comment') }}
        </button>

        <button
          v-if="
            (loggedIn && userData.username === post.user.username)
              || (
                postType === 'team'
                && teamUserRoleAndAccess !== undefined
                && ['admin', 'moderator'].includes(teamUserRoleAndAccess.role)
              )
          "
          v-tooltip="'Delete this post'"
          aria-label="Delete this post"
          type="button"
          class="btn-transparent"
          :disabled="deletionInProgress"
          @click="deletePost"
        >
          <spinner v-if="deletionInProgress" />

          <trash2-icon v-else />
        </button>
      </div>
    </div>

    <div
      v-show="commentsVisible"
      class="comments-content"
    >
      <template v-if="comments.length">
        <alert v-if="singleThread">
          <p>Your view of this post's comments is currently limited.</p>

          <button
            type="button"
            class="btn btn-primary"
            @click="viewAllComments"
          >
            View All Comments
          </button>
        </alert>

        <div
          ref="comments"
          class="comments subtle-scrollbar"
        >
          <comment
            v-for="comment of commentsToShow"
            :key="`comment${comment.id}`"
            :comment="comment"
            :first-top-level-comment="firstTopLevelComment"
            :last-top-level-comment="lastTopLevelComment"
            :depth="comment.path.length - startDepth"
            :post-id="post.id"
            :post-author="post.user.username"
            :team-slug="teamSlug"
            :team-user-role-and-access="teamUserRoleAndAccess"
            :active="activeComment === comment.id"
            :hidden-comments="hiddenComments"
            @commentHidden="toggleCommentHidden(comment.id)"
            @commentShown="toggleCommentHidden(comment.id)"
            @commentClicked="commentClicked(comment)"
            @replyCreated="commentCreated"
            @nextCommentButtonClicked="navigateToCommentByDirection($event, 'next')"
            @prevCommentButtonClicked="navigateToCommentByDirection($event, 'prev')"
            @rootCommentButtonClicked="navigateToCommentById($event)"
            @parentCommentButtonClicked="navigateToCommentById($event)"
            @readMoreClicked="viewSingleThread(comment)"
            @commentDeleted="commentDeleted(comment.id)"
          />
        </div>
      </template>

      <comment-form
        v-if="
          loggedIn
            && (
              postType === 'user'
              || (
                teamUserRoleAndAccess !== undefined
                && teamUserRoleAndAccess.access === 'write'
              )
            )
        "
        :post-id="post.id"
        @commentCreated="commentCreated"
      />
    </div>
  </div>
</template>

<script lang="ts">
import linkify from 'linkifyjs/html';
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent, nextTick, PropType } from 'vue';
import { mapState } from 'vuex';
import { ClockIcon, Trash2Icon } from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { CommentInterface, PostInterface } from '@/interfaces/posts';
import { UserRoleAndAccessInterface } from '@/interfaces/teams';
import Attachments from './Attachments.vue';
import Comment from './Comment.vue';
import CommentForm from './CommentForm.vue';
import Like from './Like.vue';

export default defineComponent({
  components: {
    ClockIcon,
    Trash2Icon,
    ProfileImage,
    Attachments,
    Comment,
    CommentForm,
    Like,
  },
  props: {
    post: {
      type: Object as PropType<PostInterface>,
      required: true,
    },
    teamSlug: {
      type: String,
      required: false,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    teamUserRoleAndAccess: {
      type: Object as PropType<UserRoleAndAccessInterface>,
      required: false,
    },
  },
  emits: [
    'postDeleted',
  ],
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    postType: 'user' as 'user' | 'team',
    audienceMap: {
      p: 'Public',
      f: 'Followers',
      mf: 'Mutual Followers',
    },
    deletionInProgress: false,
    comments: [] as Array<CommentInterface>,
    firstTopLevelComment: 0,
    lastTopLevelComment: 0,
    startDepth: 0,
    singleThread: null as null | number,
    activeComment: null as null | number,
    hiddenComments: [] as Array<number>,
    commentsVisible: true,
    viewerOptions: {
      filter(image: HTMLImageElement) {
        return image.dataset.fullSrc !== undefined;
      },
      url(image: HTMLImageElement) {
        return image.dataset.fullSrc;
      },
    },
  }),
  computed: {
    ...mapState([
      'timestampKeySuffix',
    ]),
    commentsToShow(): Array<CommentInterface> {
      if (this.singleThread === null) {
        return this.comments.filter((c: CommentInterface) => c.path.length <= 5);
      }

      const comments = this.comments.filter(
        // eslint-disable-next-line max-len
        (c: CommentInterface) => c.path.includes(this.singleThread as number) && c.path.length - this.startDepth <= 5,
      );

      if (comments.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const comment of this.comments) {
          if (comment.id === comments[0].path[comments[0].path.length - 2]) {
            comments.unshift(comment);
            break;
          }
        }
      }

      return comments;
    },
    postHtml(): string {
      return linkify(
        this.linebreaksbr(this.post.body),
        {
          defaultProtocol: 'https',
        },
      );
    },
  },
  created() {
    if (this.teamUserRoleAndAccess !== undefined && this.teamUserRoleAndAccess.role !== undefined) {
      this.postType = 'team';
    }

    if (this.post.comments.length) {
      this.comments = this.post.comments;
      const topLevelComments = this.comments.filter((c: CommentInterface) => c.path.length === 1);
      this.firstTopLevelComment = topLevelComments[0].id;
      this.lastTopLevelComment = topLevelComments[topLevelComments.length - 1].id;
    }
  },
  methods: {
    deletePost() {
      this.$swal.fire({
        title: 'Are You Sure?',
        text: 'Do you really want to delete this post?',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete It',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.deletionInProgress = true;

          const responseData = await this.api({
            url: `posts/${this.post.id}/`,
            method: 'DELETE',
          });

          this.deletionInProgress = false;

          if (responseData.status === 204) {
            this.$emit('postDeleted');
          } else {
            const title = 'Failed to Delete This Post';
            let text;

            if (responseData.status === 404) {
              text = 'This post no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
    commentClicked(comment: CommentInterface) {
      if (this.activeComment !== comment.id) {
        this.activeComment = comment.id;
      } else {
        this.activeComment = null;
      }
    },
    commentCreated(newComment: CommentInterface) {
      // We do not want the comment to say that it was posted in the future
      // (i.e. "in less than a minute")

      // eslint-disable-next-line no-param-reassign
      newComment.created = new Date(Date.now() - 2000).toISOString();
      // eslint-disable-next-line no-param-reassign
      newComment.has_children = false;

      if (newComment.path.length === 1) {
        // This is a top-level comment, so add it to the bottom.

        this.comments = this.comments.concat([newComment]);
        this.lastTopLevelComment = newComment.id;

        if (this.firstTopLevelComment === 0) {
          this.firstTopLevelComment = newComment.id;
        }
      } else {
        // This is a reply, so add it in the correct spot.

        const newPath = `${newComment.path.slice(0, -1).join('-')}-`;
        let insertionIndex = 0;
        let found = false;

        // eslint-disable-next-line no-restricted-syntax
        for (const comment of this.comments) {
          const path = `${comment.path.join('-')}-`;

          if (path.startsWith(newPath)) {
            comment.has_children = true;
            found = true;
          } else if (found) {
            break;
          }

          insertionIndex += 1;
        }

        this.comments.splice(insertionIndex, 0, newComment);
      }

      nextTick(() => {
        const commentElem = document.querySelector(`.js-comment[data-id="${newComment.id}"]`) as HTMLDivElement;

        if (commentElem) {
          this.scrollToComment(commentElem);
          this.activeComment = newComment.id;
        }
      });
    },
    commentDeleted(deletedCommentId: number) {
      const changeActiveComment = 0;
      const comments = [] as Array<CommentInterface>;

      this.comments.forEach((comment) => {
        if (comment.path.includes(deletedCommentId)) {
          if (comment.id === this.activeComment) {
            this.activeComment = null;
          }

          if (comment.id === this.singleThread) {
            const currentRoute = this.$router.currentRoute.value;

            if (currentRoute.name === 'user_comment') {
              this.$router.push({
                name: 'user_post',
                params: {
                  username: this.post.user.username as string,
                  postId: this.post.id,
                },
              });
            } else if (currentRoute.name === 'team_comment') {
              this.$router.push({
                name: 'team_post',
                params: {
                  teamSlug: this.teamSlug,
                  postId: this.post.id,
                },
              });
            }
          }
        } else {
          comments.push(comment);
        }
      });

      this.comments = comments;

      const topLevelComments = comments.filter((c) => c.path.length === 1);

      if (topLevelComments.length) {
        this.firstTopLevelComment = topLevelComments[0].id;
        this.lastTopLevelComment = topLevelComments[topLevelComments.length - 1].id;
      } else {
        this.firstTopLevelComment = 0;
        this.lastTopLevelComment = 0;
      }

      if (changeActiveComment) {
        this.activeComment = changeActiveComment;
      }
    },
    getNextSibling(elem: HTMLDivElement, selector: string) {
      let sibling = elem.nextElementSibling;

      if (!selector) {
        return sibling;
      }

      while (sibling) {
        if (sibling.matches(selector)) {
          break;
        }

        sibling = sibling.nextElementSibling;
      }

      return sibling;
    },
    getPreviousSibling(elem: HTMLDivElement, selector: string) {
      let sibling = elem.previousElementSibling;

      if (!selector) {
        return sibling;
      }

      while (sibling) {
        if (sibling.matches(selector)) {
          break;
        }

        sibling = sibling.previousElementSibling;
      }

      return sibling;
    },
    navigateToCommentByDirection(comment: HTMLDivElement, direction: 'prev' | 'next') {
      if (direction === 'next') {
        const nextCommentElem = this.getNextSibling(comment, '.comment-level-1') as HTMLDivElement;

        if (nextCommentElem) {
          this.activeComment = parseInt(nextCommentElem.dataset.id as string, 10);
          this.scrollToComment(nextCommentElem);
        }
      } else {
        const prevCommentElem = this.getPreviousSibling(comment, '.comment-level-1') as HTMLDivElement;

        if (prevCommentElem) {
          this.activeComment = parseInt(prevCommentElem.dataset.id as string, 10);
          this.scrollToComment(prevCommentElem);
        }
      }
    },
    navigateToCommentById(id: number) {
      const commentElem = document.querySelector(`.js-comment[data-id="${id}"]`);

      if (commentElem) {
        this.activeComment = id;
        this.scrollToComment(commentElem as HTMLDivElement);
      } else {
        const comment = this.comments.filter((c: CommentInterface) => c.id === id)[0];
        this.viewSingleThread(comment);
      }
    },
    scrollToComment(commentElem: HTMLDivElement) {
      const commentsElem = this.$refs.comments as HTMLDivElement;
      const commentsBounding = commentsElem.getBoundingClientRect();
      const headerHeight = parseInt(
        getComputedStyle(document.documentElement).getPropertyValue('--is-authenticated-header-height'),
        10,
      );

      if (commentsBounding.top - headerHeight < 0) {
        window.scrollTo({
          top: commentsElem.offsetTop - headerHeight,
          behavior: 'smooth',
        });
      }

      commentsElem.scrollTo({
        top: commentElem.offsetTop,
        behavior: 'smooth',
      });
    },
    timestampLink() {
      if (this.postType === 'user') {
        return {
          name: 'user_post',
          params: {
            username: this.post.user.username,
            postId: this.post.id,
          },
        };
      }

      return {
        name: 'team_post',
        params: {
          teamSlug: this.teamSlug,
          postId: this.post.id,
        },
      };
    },
    toggleCommentHidden(id: number) {
      if (this.hiddenComments.includes(id)) {
        this.hiddenComments = this.hiddenComments.filter((i) => i !== id);
      } else {
        this.hiddenComments.push(id);
      }
    },
    viewAllComments() {
      this.startDepth = 0;
      this.singleThread = null;
    },
    viewSingleThread(comment: CommentInterface) {
      let startDepth = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const c of this.comments) {
        if (c.id === comment.id) {
          startDepth = comment.path.length - 2;
          break;
        }
      }

      if (startDepth >= 1) {
        this.startDepth = startDepth;
        this.singleThread = comment.id;
      } else {
        this.startDepth = 0;
        this.singleThread = null;
      }

      this.activeComment = comment.id;

      nextTick(() => {
        this.scrollToComment(
          document.querySelector(`.js-comment[data-id="${comment.id}"]`) as HTMLDivElement,
        );
      });
    },
  },
});
</script>

<style lang="scss" scoped>
  .post-top {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .name-timestamp-and-audience {
    margin-left: 0.5rem;

    > a {
      color: #fff;
    }
  }

  .timestamp-and-audience {
    margin-top: 0.25rem;
    font-size: 80%;
    color: var(--gray-light);

    .feather-clock {
      margin-right: 0.5em;
    }
  }

  .below-post-body {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--gray);
  }

  .buttons {
    display: flex;

    button + button {
      margin-left: 20px;
    }
  }

  .comments-content {
    margin-top: 1rem;
    border-top: 1px solid var(--gray);
  }

  .comments {
    position: relative;
    margin: 0 calc(var(--horizontal-padding) * -1);
    max-height: min(422px, calc(70vh - var(--is-authenticated-header-height)));
    overflow-y: auto;
  }
</style>
