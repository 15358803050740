
import { defineComponent, PropType } from 'vue';
import { AttachmentImageInterface, AttachmentVideoInterface } from '@/interfaces/posts';

export default defineComponent({
  props: {
    attachment: {
      type: Object as PropType<AttachmentImageInterface | AttachmentVideoInterface>,
      required: true,
    },
    clickAction: {
      type: String,
      required: false,
      default: 'normal',
      validator: (value: string) => [
        'normal',
        'nothing',
      ].includes(value) || value.startsWith('goToPost'),
    },
    fixedSize: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: [
    'attachmentClicked',
  ],
  methods: {
    attachmentCoverClicked() {
      if (this.clickAction.startsWith('goToPost')) {
        this.$router.push({
          name: 'post',
          params: {
            postId: parseInt(this.clickAction.substring(8), 10),
          },
        });
      } else if (this.clickAction === 'nothing') {
        this.$emit('attachmentClicked');
      }
    },
  },
});
