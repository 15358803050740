<template>
  <div class="attachments">
    <template
      v-for="(attachment, attachmentIndex) of attachments"
    >
      <a
        v-if="attachment.type === 'image'"
        :key="`attachment${attachmentIndex}`"
        :href="attachment.url.full"
        @click.prevent="imageLinkClick(attachment.url.full)"
      >
        <img
          :src="attachment.url.thumbnail"
          alt
        >
      </a>

      <video
        v-else
        :key="`attachment${attachmentIndex}`"
        :src="`${attachment.url}#t=0.1`"
        controls
        playsinline
      />
    </template>

    <vue-easy-lightbox
      v-if="imgs.length"
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="visible = false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AttachmentImageInterface, AttachmentVideoInterface } from '@/interfaces/posts';

export default defineComponent({
  props: {
    attachments: {
      type: Array as () => Array<AttachmentImageInterface | AttachmentVideoInterface>,
      required: true,
    },
  },
  data() {
    const imgs = [] as Array<{ src: string }>;

    this.attachments.forEach((attachment) => {
      if (attachment.type === 'image') {
        imgs.push({
          src: attachment.url.full,
        });
      }
    });

    return {
      visible: false,
      imgs,
      index: 0,
    };
  },
  methods: {
    imageLinkClick(url: string) {
      let index = 0;

      this.imgs.forEach((img, imgIndex) => {
        if (img.src === url) {
          index = imgIndex;
        }
      });

      this.index = index;
      this.visible = true;
    },
  },
});
</script>

<style lang="scss" scoped>
  img,
  video {
    display: block;
    margin-top: 1rem;
    max-width: 100%;
    height: auto;
  }
</style>
