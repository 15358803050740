
import { defineComponent, PropType } from 'vue';
import { CommentInterface, PostInterface } from '@/interfaces/posts';
import { UserProfileInterface } from '@/interfaces/users';
import { TeamDetailInterface } from '@/interfaces/teams';
import Post from './Post.vue';
import CreatePost from './CreatePost.vue';

export default defineComponent({
  components: {
    Post,
    CreatePost,
  },
  props: {
    user: {
      type: Object as PropType<UserProfileInterface>,
      required: false,
      default: undefined,
    },
    team: {
      type: Object as PropType<TeamDetailInterface>,
      required: false,
      default: undefined,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    posts: {} as {
      next: string | null,
      previous: string | null,
      results: Array<PostInterface>,
    },
    morePostsStatus: 'idle' as 'idle' | 'loading' | 'error',
    observer: null as null | IntersectionObserver,
  }),
  created() {
    this.loadPosts();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    async loadPosts() {
      this.status = 'loading';

      let url = 'posts/';

      if (this.user !== undefined) {
        url += `?user=${this.user.username}`;
      } else if (this.team !== undefined) {
        url += `?team=${this.team.slug}`;
      }

      const responseData = await this.api({
        url,
      });

      if (responseData.status === 200) {
        const posts = responseData.body;

        posts.results.forEach((post: PostInterface) => {
          if (post.comments.length) {
            let previousPath = '';
            let index = 0;
            post.comments.forEach((comment: CommentInterface) => {
              const path = `${comment.path.join('-')}-`;

              if (index) {
                // eslint-disable-next-line no-param-reassign
                post.comments[index - 1].has_children = path.startsWith(previousPath);
              }

              previousPath = path;
              index += 1;
            });

            // eslint-disable-next-line no-param-reassign
            post.comments[index - 1].has_children = false;
          }
        });

        this.posts = posts;
        this.status = 'loaded';

        if (this.posts.next) {
          this.$nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMorePosts();
                }
              });
            });

            this.observer.observe(this.$refs.loadMorePostsButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMorePosts() {
      this.morePostsStatus = 'loading';

      const responseData = await this.api({
        url: this.posts.next as string,
      });

      if (responseData.status === 200) {
        const posts = responseData.body;

        posts.results.forEach((post: PostInterface) => {
          if (post.comments.length) {
            let previousPath = '';
            let index = 0;
            post.comments.forEach((comment: CommentInterface) => {
              const path = `${comment.path.join('-')}-`;

              if (index) {
                // eslint-disable-next-line no-param-reassign
                post.comments[index - 1].has_children = path.startsWith(previousPath);
              }

              previousPath = path;
              index += 1;
            });

            // eslint-disable-next-line no-param-reassign
            post.comments[index - 1].has_children = false;
          }
        });

        this.posts.next = responseData.body.next;
        this.posts.previous = responseData.body.previous;
        this.posts.results = this.posts.results.concat(posts.results);
        this.morePostsStatus = 'idle';
      } else {
        this.morePostsStatus = 'error';
      }
    },
    postCreated(newPost: PostInterface) {
      // We do not want the post to say that it was posted in the future (i.e.
      // "in less than a minute")

      // eslint-disable-next-line no-param-reassign
      newPost.created = new Date(Date.now() - 2000).toISOString();
      this.posts.results.unshift(newPost);
    },
    postDeleted(id: number) {
      this.posts.results = this.posts.results.filter((p) => p.id !== id);
    },
  },
});
