
import { defineComponent, nextTick } from 'vue';
import { PaperclipIcon, SendIcon } from '@zhuowenli/vue-feather-icons';
import AttachmentUpload from '@/components/AttachmentUpload.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { NewAttachmentInterface } from '@/interfaces/posts';

export default defineComponent({
  components: {
    PaperclipIcon,
    SendIcon,
    AttachmentUpload,
    ProfileImage,
  },
  props: {
    postId: {
      type: Number,
      required: true,
    },
    parentCommentId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: [
    'commentCreated',
  ],
  data: () => ({
    body: '',
    attachment: null as null | NewAttachmentInterface,
    fileInputKeySuffix: 0,
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
  }),
  watch: {
    formErrors() {
      if (this.formErrors.non_field_errors !== undefined) {
        nextTick(this.scrollToFirstError);
      }
    },
  },
  methods: {
    attachFile(e: Event) {
      this.fileInputKeySuffix += 1;
      const fileInput = e.target as HTMLInputElement;
      const file = (fileInput.files as FileList)[0];

      if (file) {
        if (file.size <= process.env.VUE_APP_MAX_ATTACHMENT_BYTES) {
          const reader = new FileReader();

          reader.onload = () => {
            this.attachment = {
              key: Date.now(),
              status: 'Idle',
              file,
            };
          };

          reader.readAsDataURL(file);
        } else {
          this.formErrors = {
            non_field_errors: ['That file is too large to upload.'],
          };
        }
      }
    },
    focusTextarea() {
      const body = this.$refs.body as HTMLTextAreaElement;
      body.focus();
    },
    async submitForm() {
      this.formErrors = {};

      if (this.body.trim() === '' && this.attachment === null) {
        this.formErrors = {
          non_field_errors: ['Please write a message and/or attach a file.'],
        };
        return;
      }

      if (this.attachment && this.attachment.status !== 'Uploaded') {
        let message;

        if (['Idle', 'Uploading'].includes(this.attachment.status)) {
          message = 'The file is still uploading.';
        } else {
          message = 'The file failed to upload. Please either remove it or try uploading it again.';
        }

        this.formErrors = {
          non_field_errors: [message],
        };
        return;
      }

      this.formSubmitting = true;

      const data = {
        post_id: this.postId,
        body: this.body,
      } as Record<string, unknown>;

      if (this.attachment) {
        data.attachment_id = this.attachment.id;
      }

      if (this.parentCommentId) {
        data.parent_comment_id = this.parentCommentId;
      }

      const responseData = await this.api({
        url: 'comments/',
        method: 'POST',
        json: data,
      });

      this.formSubmitting = false;

      if (responseData.status === 201) {
        this.body = '';
        this.attachment = null;
        this.$emit('commentCreated', responseData.body);
      } else if (responseData.status === 400) {
        if (
          typeof responseData.body === 'object'
          && responseData.body.parent_comment_id !== undefined
        ) {
          this.formErrors = {
            non_field_errors: ['The comment you are trying to reply to no longer exists.'],
          };
        } else if (
          typeof responseData.body === 'object'
          && responseData.body.post_id !== undefined
        ) {
          this.formErrors = {
            non_field_errors: ['The post you are trying to comment on no longer exists.'],
          };
        } else {
          this.formErrors = responseData.body;
        }
      } else {
        this.formErrors = {
          non_field_errors: ['Unable to submit your comment. Please check your connection and try again.'],
        };
      }
    },
    textareaKeydown(e: KeyboardEvent) {
      if (e.key === 'Enter' && window.app.keyboardType === 'physical') {
        e.preventDefault();
        this.submitForm();
      }
    },
    triggerFileInputClick() {
      (this.$refs.fileInput as HTMLInputElement).click();
    },
  },
});
