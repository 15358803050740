
import { defineComponent, PropType } from 'vue';
import Modal from '@/components/Modal.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { CommentInterface, PostInterface } from '@/interfaces/posts';
import { UserInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    Modal,
    ProfileImage,
  },
  props: {
    post: {
      type: Object as PropType<PostInterface>,
      required: false,
      default: undefined,
    },
    comment: {
      type: Object as PropType<CommentInterface>,
      required: false,
      default: undefined,
    },
  },
  data: () => ({
    postOrComment: '',
    liked: false,
    totalLikes: 0,
    toggled: false,
    toggling: false,
    showSpinner: false,
    usersModalShowing: false,
    userListStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'not_found' | 'error',
    userList: {} as {
      next: string | null,
      previous: string | null,
      results: Array<UserInterface>,
    },
  }),
  created() {
    if (this.post !== undefined) {
      this.postOrComment = 'post';
      this.liked = this.post.liked_by_user;
      this.totalLikes = this.post.total_likes;
    } else if (this.comment !== undefined) {
      this.postOrComment = 'comment';
      this.liked = this.comment.liked_by_user;
      this.totalLikes = this.comment.total_likes;
    }
  },
  methods: {
    showUsersModal() {
      this.usersModalShowing = true;
      this.loadUserList();
    },
    async loadUserList() {
      if (this.userListStatus === 'loading') {
        return;
      }

      this.userListStatus = 'loading';

      let url = '';

      if (this.post !== undefined) {
        url = `posts/${this.post.id}/likes/?page_size=100`;
      } else if (this.comment !== undefined) {
        url = `comments/${this.comment.id}/likes/?page_size=100`;
      }

      const responseData = await this.api({
        url,
      });

      if (responseData.status === 200) {
        this.userList = responseData.body;
        this.userListStatus = 'loaded';
      } else if (responseData.status === 404) {
        this.userListStatus = 'not_found';
      } else {
        this.userListStatus = 'error';
      }
    },
    async toggleLike() {
      if (!this.toggling) {
        this.toggling = true;

        const showSpinnerTimeoutID = setTimeout(() => {
          this.showSpinner = true;
        }, 100);

        let url = '';

        if (this.post !== undefined) {
          url = `posts/${this.post.id}/toggle_like/`;
        } else if (this.comment !== undefined) {
          url = `comments/${this.comment.id}/toggle_like/`;
        }

        const responseData = await this.api({
          url,
          method: 'POST',
          json: { like: !this.liked },
        });

        clearTimeout(showSpinnerTimeoutID);
        this.toggling = false;
        this.showSpinner = false;

        if (responseData.status === 200) {
          this.liked = !this.liked;

          if (this.liked) {
            this.totalLikes += 1;
          } else {
            this.totalLikes -= 1;
          }

          this.toggled = false;

          setTimeout(() => {
            this.toggled = true;
          }, 50);
        } else {
          setTimeout(() => {
            const likeOrUnlike = this.liked ? 'Unlike' : 'Like';
            const title = `Failed to ${likeOrUnlike} This ${this.capitalizeWords(this.postOrComment)}`;
            let text;

            if (responseData.status === 404) {
              text = `This ${this.postOrComment} no longer exists.`;
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }, 50);
        }
      }
    },
  },
});
