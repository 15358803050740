<template>
  <div
    v-show="visible"
    ref="comment"
    :class="
      `js-comment comment comment-level-${depth}`
        + (active && !hidden ? ' active' : '')
        + (hidden ? ' hidden' : '')
    "
    :data-id="comment.id"
    @click="commentClicked"
  >
    <div
      v-if="active && !hidden"
      class="active-comment-actions"
      @click.stop
    >
      <button
        type="button"
        class="btn-transparent"
        aria-label="Hide comment"
        @click="hideComment"
      >
        Hide
      </button>

      <div>
        <template v-if="comment.path.length === 1">
          <button
            v-if="comment.id !== firstTopLevelComment"
            type="button"
            class="btn-transparent"
            aria-label="Go to the previous comment"
            @click="$emit('prevCommentButtonClicked', $refs.comment)"
          >
            <arrow-up-icon />
            Prev
          </button>

          <button
            v-if="comment.id !== lastTopLevelComment"
            type="button"
            class="btn-transparent"
            aria-label="Go to the next comment"
            @click="$emit('nextCommentButtonClicked', $refs.comment)"
          >
            <arrow-down-icon />
            Next
          </button>
        </template>

        <template v-else>
          <button
            type="button"
            class="btn-transparent"
            aria-label="Go to the root comment"
            @click="$emit('rootCommentButtonClicked', comment.path[0])"
          >
            <arrow-up-icon />
            Root
          </button>

          <button
            type="button"
            class="btn-transparent"
            aria-label="Go to the parent comment"
            @click="$emit('parentCommentButtonClicked', comment.path[comment.path.length - 2])"
          >
            <arrow-up-icon />
            Parent
          </button>
        </template>
      </div>
    </div>

    <div class="comment-header">
      <router-link
        v-if="!hidden"
        :to="{ name: 'user_profile', params: { username: comment.user.username } }"
        class="profile-image"
      >
        <profile-image
          :user="comment.user"
          :size="34"
        />
      </router-link>

      <profile-image
        v-else
        :user="comment.user"
        class="profile-image"
        :size="34"
      />

      <div
        class="comment-author"
        :class="{ 'post-author': comment.user.username === postAuthor }"
      >
        <router-link
          v-if="!hidden"
          :to="{ name: 'user_profile', params: { username: comment.user.username } }"
          class="comment-author-name"
        >
          {{ comment.user.first_name }} {{ comment.user.last_name }}
        </router-link>

        <span
          v-else
          class="comment-author-name"
        >
          {{ comment.user.first_name }} {{ comment.user.last_name }}
        </span>

        <span
          v-if="comment.user.username === postAuthor"
          class="comment-author-author"
        >Author</span>
      </div>

      <div class="comment-time">
        <router-link
          v-if="!hidden"
          v-tooltip="timestampDisplay(comment.created)"
          :to="timestampLink()"
        >
          <clock-icon size="12" />

          <span :key="`created${timestampKeySuffix}`">
            {{ timestampDistanceDisplay(comment.created) }}
          </span>
        </router-link>

        <span v-else>
          <clock-icon size="12" />

          <span :key="`created${timestampKeySuffix}`">
            {{ timestampDistanceDisplay(comment.created) }}
          </span>
        </span>
      </div>
    </div>

    <div v-show="!hidden">
      <div
        v-if="comment.body !== ''"
        class="comment-body"
        v-html="commentHtml()"
      />

      <template v-if="comment.attachment_set.length">
        <attachment :attachment="comment.attachment_set[0]" />
      </template>

      <div
        class="likes-and-buttons"
        @click.stop
      >
        <like :comment="comment" />

        <div class="buttons">
          <button
            v-tooltip="'Reply to this comment'"
            aria-label="Reply to this comment"
            type="button"
            class="btn-transparent"
            @click="replyClicked"
          >
            <mdicon
              name="reply"
              size="16"
            />
          </button>

          <template
            v-if="
              (loggedIn && userData.username === comment.user.username)
                || (
                  commentType === 'team'
                  && teamUserRoleAndAccess !== undefined
                  && ['admin', 'moderator'].includes(teamUserRoleAndAccess.role)
                )
            "
          >
            <button
              v-if="!deletionInProgress"
              v-tooltip="'Delete this comment'"
              aria-label="Delete this comment"
              type="button"
              class="btn-transparent"
              @click="deleteComment"
            >
              <trash2-icon />
            </button>

            <spinner v-else />
          </template>
        </div>

        <div
          v-if="depth === 5 && comment.has_children"
          class="read-more"
        >
          <button
            type="button"
            class="btn-transparent"
            @click="$emit('readMoreClicked')"
          >
            Read more of this conversation
          </button>
        </div>
      </div>

      <comment-form
        v-if="showReplyForm"
        ref="commentForm"
        :post-id="postId"
        :parent-comment-id="comment.id"
        @commentCreated="replyCreated"
      />
    </div>
  </div>
</template>

<script lang="ts">
import linkify from 'linkifyjs/html';
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent, nextTick, PropType } from 'vue';
import { mapState } from 'vuex';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ClockIcon,
  Trash2Icon,
} from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { CommentInterface } from '@/interfaces/posts';
import { UserRoleAndAccessInterface } from '@/interfaces/teams';
import Attachment from './Attachment.vue';
import CommentForm from './CommentForm.vue';
import Like from './Like.vue';

export default defineComponent({
  components: {
    ArrowDownIcon,
    ArrowUpIcon,
    ClockIcon,
    Trash2Icon,
    ProfileImage,
    Attachment,
    CommentForm,
    Like,
  },
  props: {
    comment: {
      type: Object as PropType<CommentInterface>,
      required: true,
    },
    firstTopLevelComment: {
      type: Number,
      required: true,
    },
    lastTopLevelComment: {
      type: Number,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    postId: {
      type: Number,
      required: true,
    },
    postAuthor: {
      type: String,
      required: false,
      default: '',
    },
    teamSlug: {
      type: String,
      required: false,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    teamUserRoleAndAccess: {
      type: Object as PropType<UserRoleAndAccessInterface>,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: true,
    },
    hiddenComments: {
      type: Array as () => Array<number>,
      required: false,
      default() {
        return [];
      },
    },
  },
  emits: [
    'commentClicked',
    'commentShown',
    'commentHidden',
    'commentDeleted',
    'readMoreClicked',
    'replyCreated',
    'prevCommentButtonClicked',
    'nextCommentButtonClicked',
    'rootCommentButtonClicked',
    'parentCommentButtonClicked',
  ],
  data: () => ({
    commentType: 'user' as 'user' | 'team',
    hidden: false,
    showReplyForm: false,
    deletionInProgress: false,
  }),
  computed: {
    ...mapState([
      'timestampKeySuffix',
    ]),
    visible(): boolean {
      // eslint-disable-next-line no-restricted-syntax
      for (const id of this.hiddenComments) {
        if (this.comment.id !== id && this.comment.path.includes(id)) {
          return false;
        }
      }

      return true;
    },
  },
  created() {
    if (this.teamUserRoleAndAccess !== undefined && this.teamUserRoleAndAccess.role !== undefined) {
      this.commentType = 'team';
    }
  },
  methods: {
    commentClicked() {
      if (this.hidden) {
        this.hidden = false;
        this.$emit('commentShown');
      } else {
        this.$emit('commentClicked');
      }
    },
    commentHtml() {
      return linkify(
        this.linebreaksbr(this.comment.body),
        {
          defaultProtocol: 'https',
        },
      );
    },
    hideComment() {
      this.hidden = true;
      this.$emit('commentHidden');
    },
    replyClicked() {
      this.showReplyForm = true;

      nextTick(() => {
        (this.$refs.commentForm as InstanceType<typeof CommentForm>).focusTextarea();
      });
    },
    replyCreated(newComment: CommentInterface) {
      this.showReplyForm = false;
      this.$emit('replyCreated', newComment);
    },
    timestampLink() {
      if (this.commentType === 'user') {
        return {
          name: 'user_comment',
          params: {
            username: this.postAuthor,
            postId: this.postId,
            commentId: this.comment.id,
          },
        };
      }

      return {
        name: 'team_comment',
        params: {
          teamSlug: this.teamSlug,
          postId: this.postId,
          commentId: this.comment.id,
        },
      };
    },
    deleteComment() {
      this.$swal.fire({
        title: 'Are You Sure?',
        text: 'Do you really want to delete this comment?',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete It',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.deletionInProgress = true;

          const responseData = await this.api({
            url: `comments/${this.comment.id}/`,
            method: 'DELETE',
          });

          this.deletionInProgress = false;

          if (responseData.status === 204) {
            this.$emit('commentDeleted');
          } else {
            const title = 'Failed to Delete This Comment';
            let text;

            if (responseData.status === 404) {
              text = 'This comment no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
  },
});
</script>

<style lang="scss" scoped>
  .comment {
    position: relative;
    margin-top: var(--padding);
    word-break: break-word;
    padding: 40px var(--horizontal-padding) 20px;

    &.active {
      background-color: var(--gray-dark);
    }

    &:first-of-type {
      margin-top: 0px;
    }
  }

  .active-comment-actions {
    position: absolute;
    top: 10px;
    right: var(--horizontal-padding);
    left: var(--horizontal-padding);
    display: flex;
    justify-content: space-between;

    button {
      text-transform: uppercase;

      + button {
        margin-left: 20px;
      }
    }

    div {
      text-align: right;
    }
  }

  .comment-level-2:before,
  .comment-level-3:before,
  .comment-level-4:before,
  .comment-level-5:before {
    content: '';
    position: absolute;
    top: 6px;
    display: block;
    width: 1px;
    height: calc(100% - 12px);
    background-color: var(--gray);
  }

  .comment-level-2 {
    margin-top: 0px;
    padding-left: 40px;

    &:before {
      left: 20px;
    }

    .active-comment-actions {
      left: 40px;
    }
  }

  .comment-level-3 {
    margin-top: 0px;
    padding-left: 60px;

    &:before {
      left: 40px;
    }

    .active-comment-actions {
      left: 60px;
    }
  }

  .comment-level-4 {
    margin-top: 0px;
    padding-left: 80px;

    &:before {
      left: 60px;
    }

    .active-comment-actions {
      left: 80px;
    }
  }

  .comment-level-5 {
    margin-top: 0px;
    padding-left: 100px;

    &:before {
      left: 80px;
    }

    .active-comment-actions {
      left: 100px;
    }
  }

  .comment-header {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      'profile-image comment-author'
      'profile-image comment-time';
    margin-bottom: 20px;
    position: relative;
  }

  .comment-header-profile-page {
    display: block;
    font-weight: bold;
  }

  .profile-image {
    grid-area: profile-image;
  }

  .comment-author {
    grid-area: comment-author;
    align-self: end;
    font-weight: 500;
  }

  .comment-author-name {
    padding-left: 8px;
    color: #fff;
  }

  .comment-author-author {
    margin-left: 0.25rem;
    padding: 0.1rem 0.25rem;
    font-size: 0.6rem;
    background-color: var(--gray-light);
    color: #000;
    text-transform: uppercase;
    border-radius: 5px;
  }

  .hidden {
    .profile-image {
      filter: grayscale(1);
    }

    .comment-author .comment-author-name {
      color: var(--gray-light);
      font-style: italic;
    }

    .post-author .comment-author-author {
      background-color: var(--gray);
    }

    .comment-time {
      color: var(--gray-light);
      font-style: italic;
    }
  }

  .comment-time {
    grid-area: comment-time;
    margin-top: 0.25rem;
    padding-left: 8px;
    font-size: 80%;

    .feather-clock {
      margin-right: 0.5em;
    }
  }

  .comment-body,
  .attachment {
    margin-bottom: 0.5rem;
  }

  .likes-and-buttons {
    display: grid;
    grid-gap: 0.25rem;
    grid-template-columns: 66px 1fr;
    align-items: center;
    margin-top: 20px;

    button + button,
    .spinner {
      margin-left: 20px !important;
    }
  }

  .buttons {
    text-align: right;
  }

  .read-more {
    grid-column: 1 / -1;
    font-size: 90%;
  }

  @media (max-width: 410px) {
    .active-comment-actions,
    .likes-and-buttons {
      font-size: 0.8rem;

      button + button {
        margin-left: 0.5rem !important;
      }
    }
  }
</style>
