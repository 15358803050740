<template>
  <spinner
    v-if="status === 'loading'"
    preset="large"
  />

  <template v-else-if="status === 'error'">
    <alert variant="danger">
      An error occurred loading the posts. Please check your connection and
      try again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadPosts"
    >
      Try Again
    </button>
  </template>

  <template v-else-if="status === 'loaded'">
    <create-post
      v-if="
        loggedIn
          && userData.subscription_status === 'active'
          && (
            (user === undefined && team === undefined)
            || (user !== undefined && userData.username === user.username)
            || (team !== undefined && team.user_role_and_access.access === 'write')
          )
      "
      :team-slug="team !== undefined ? team.slug : ''"
      @postCreated="postCreated"
    />

    <template v-if="posts.results.length">
      <post
        v-for="post of posts.results"
        :key="`post${post.id}`"
        :post="post"
        :team-slug="team !== undefined ? team.slug : ''"
        :team-user-role-and-access="
          team !== undefined ? team.user_role_and_access : undefined
        "
        @postDeleted="postDeleted(post.id)"
      />

      <div class="load-more-content">
        <button
          v-show="morePostsStatus === 'idle' && posts.next"
          ref="loadMorePostsButton"
          type="button"
          class="btn btn-outline-primary load-more-posts-button"
          @click="loadMorePosts"
        >
          Load More Posts
        </button>

        <spinner
          v-if="morePostsStatus === 'loading'"
          preset="large"
        />

        <template v-else-if="morePostsStatus === 'error'">
          <alert variant="danger">
            An error occurred while trying to load more posts. Please check
            your connection and try again.
          </alert>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="loadMorePosts"
          >
            Try Again
          </button>
        </template>
      </div>
    </template>

    <p
      v-else
      class="text-center"
    >
      <template v-if="user !== undefined">
        <template v-if="loggedIn && userData.username === user.username">
          You don't
        </template>

        <template v-else>
          This user doesn't
        </template>

        have any posts yet.
      </template>

      <template v-else-if="team !== undefined">
        This team doesn't have any posts yet.
      </template>

      <template v-else>
        You are not following anybody yet.
      </template>
    </p>
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { CommentInterface, PostInterface } from '@/interfaces/posts';
import { UserProfileInterface } from '@/interfaces/users';
import { TeamDetailInterface } from '@/interfaces/teams';
import Post from './Post.vue';
import CreatePost from './CreatePost.vue';

export default defineComponent({
  components: {
    Post,
    CreatePost,
  },
  props: {
    user: {
      type: Object as PropType<UserProfileInterface>,
      required: false,
      default: undefined,
    },
    team: {
      type: Object as PropType<TeamDetailInterface>,
      required: false,
      default: undefined,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    posts: {} as {
      next: string | null,
      previous: string | null,
      results: Array<PostInterface>,
    },
    morePostsStatus: 'idle' as 'idle' | 'loading' | 'error',
    observer: null as null | IntersectionObserver,
  }),
  created() {
    this.loadPosts();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    async loadPosts() {
      this.status = 'loading';

      let url = 'posts/';

      if (this.user !== undefined) {
        url += `?user=${this.user.username}`;
      } else if (this.team !== undefined) {
        url += `?team=${this.team.slug}`;
      }

      const responseData = await this.api({
        url,
      });

      if (responseData.status === 200) {
        const posts = responseData.body;

        posts.results.forEach((post: PostInterface) => {
          if (post.comments.length) {
            let previousPath = '';
            let index = 0;
            post.comments.forEach((comment: CommentInterface) => {
              const path = `${comment.path.join('-')}-`;

              if (index) {
                // eslint-disable-next-line no-param-reassign
                post.comments[index - 1].has_children = path.startsWith(previousPath);
              }

              previousPath = path;
              index += 1;
            });

            // eslint-disable-next-line no-param-reassign
            post.comments[index - 1].has_children = false;
          }
        });

        this.posts = posts;
        this.status = 'loaded';

        if (this.posts.next) {
          this.$nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMorePosts();
                }
              });
            });

            this.observer.observe(this.$refs.loadMorePostsButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMorePosts() {
      this.morePostsStatus = 'loading';

      const responseData = await this.api({
        url: this.posts.next as string,
      });

      if (responseData.status === 200) {
        const posts = responseData.body;

        posts.results.forEach((post: PostInterface) => {
          if (post.comments.length) {
            let previousPath = '';
            let index = 0;
            post.comments.forEach((comment: CommentInterface) => {
              const path = `${comment.path.join('-')}-`;

              if (index) {
                // eslint-disable-next-line no-param-reassign
                post.comments[index - 1].has_children = path.startsWith(previousPath);
              }

              previousPath = path;
              index += 1;
            });

            // eslint-disable-next-line no-param-reassign
            post.comments[index - 1].has_children = false;
          }
        });

        this.posts.next = responseData.body.next;
        this.posts.previous = responseData.body.previous;
        this.posts.results = this.posts.results.concat(posts.results);
        this.morePostsStatus = 'idle';
      } else {
        this.morePostsStatus = 'error';
      }
    },
    postCreated(newPost: PostInterface) {
      // We do not want the post to say that it was posted in the future (i.e.
      // "in less than a minute")

      // eslint-disable-next-line no-param-reassign
      newPost.created = new Date(Date.now() - 2000).toISOString();
      this.posts.results.unshift(newPost);
    },
    postDeleted(id: number) {
      this.posts.results = this.posts.results.filter((p) => p.id !== id);
    },
  },
});
</script>

<style lang="scss" scoped>
  .post + .post {
    margin-top: 2rem;
  }

  .load-more-content {
    margin-top: 1rem;
  }

  .load-more-posts-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
