
import { defineComponent, nextTick } from 'vue';
import { PaperclipIcon, SendIcon } from '@zhuowenli/vue-feather-icons';
import AttachmentUpload from '@/components/AttachmentUpload.vue';
import Modal from '@/components/Modal.vue';
import TransitionSlide from '@/components/TransitionSlide.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { NewAttachmentInterface } from '@/interfaces/posts';

export default defineComponent({
  components: {
    PaperclipIcon,
    SendIcon,
    AttachmentUpload,
    Modal,
    TransitionSlide,
    ProfileImage,
  },
  props: {
    teamSlug: {
      type: String,
      required: true,
    },
  },
  emits: [
    'postCreated',
  ],
  data: () => ({
    formShowing: false,
    audienceModalShowing: false,
    audienceMap: {
      p: 'Public',
      f: 'Followers',
      mf: 'Mutual Followers',
    },
    audience: 'mf' as 'p' | 'f' | 'mf',
    body: '',
    attachments: [] as Array<NewAttachmentInterface>,
    fileInputKeySuffix: 0,
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
  }),
  watch: {
    formErrors() {
      if (this.formErrors.non_field_errors !== undefined) {
        nextTick(this.scrollToFirstError);
      }
    },
  },
  methods: {
    attachFile(e: Event) {
      this.fileInputKeySuffix += 1;
      const fileInput = e.target as HTMLInputElement;
      const file = (fileInput.files as FileList)[0];

      if (file) {
        if (file.size <= process.env.VUE_APP_MAX_ATTACHMENT_BYTES) {
          const reader = new FileReader();

          reader.onload = () => {
            this.attachments.push({
              key: Date.now(),
              status: 'Idle',
              file,
            });
          };

          reader.readAsDataURL(file);
        } else {
          this.formErrors = {
            non_field_errors: ['That file is too large to upload.'],
          };
        }
      }
    },
    removeAttachment(attachment: NewAttachmentInterface) {
      this.attachments = this.attachments.filter((a) => a !== attachment);
    },
    focusTextarea() {
      const body = this.$refs.body as HTMLTextAreaElement;
      body.focus();
    },
    setAudience(audience: 'p' | 'f' | 'mf') {
      this.audience = audience;
      (this.$refs.audienceModal as InstanceType<typeof Modal>).closeModal();
    },
    async submitForm() {
      this.formErrors = {};

      if (this.body.trim() === '' && this.attachments.length === 0) {
        this.formErrors = {
          non_field_errors: ['Please write a message and/or attach a file.'],
        };
        return;
      }

      const unfinishedAttachments = {
        uploading: this.attachments.filter((a) => ['Idle', 'Uploading'].includes(a.status)).length,
        error: this.attachments.filter((a) => a.status === 'Error').length,
      };

      if (unfinishedAttachments.uploading || unfinishedAttachments.error) {
        let message = '';

        if (unfinishedAttachments.uploading) {
          message = `${unfinishedAttachments.uploading} file`;

          if (unfinishedAttachments.uploading === 1) {
            message += ' is';
          } else {
            message += 's are';
          }

          message += ' still uploading.';

          if (unfinishedAttachments.error) {
            message += ' In addition, ';
          }
        }

        if (unfinishedAttachments.error) {
          message = `${unfinishedAttachments.error} file`;

          if (unfinishedAttachments.error > 1) {
            message += 's';
          }

          message += ' failed to upload. Please either remove ';

          if (unfinishedAttachments.error === 1) {
            message += 'it';
          } else {
            message += 'them';
          }

          message += ' or try uploading ';

          if (unfinishedAttachments.error === 1) {
            message += 'it';
          } else {
            message += 'them';
          }

          message += ' again.';
        }

        this.formErrors = {
          non_field_errors: [message],
        };
        return;
      }

      this.formSubmitting = true;

      const data = {
        body: this.body,
      } as {
        body: string;
        // eslint-disable-next-line camelcase
        attachment_ids?: Array<number>;
        audience?: string;
        team?: string;
      };

      if (this.teamSlug === '') {
        data.audience = this.audience;
      } else {
        data.team = this.teamSlug;
      }

      if (this.attachments.length) {
        const attachmentIds = [] as Array<number>;

        this.attachments.forEach((attachment) => {
          attachmentIds.push(attachment.id as number);
        });

        data.attachment_ids = attachmentIds;
      }

      const responseData = await this.api({
        url: 'posts/',
        method: 'POST',
        json: data,
      });

      this.formSubmitting = false;

      if (responseData.status === 201) {
        this.body = '';
        this.attachments = [];
        this.$emit('postCreated', responseData.body);
      } else if (responseData.status === 400) {
        if (
          typeof responseData.body === 'object'
          && responseData.body.team !== undefined
        ) {
          this.formErrors = {
            non_field_errors: responseData.body.team,
          };
        } else {
          this.formErrors = responseData.body;
        }
      } else {
        this.formErrors = {
          non_field_errors: ['Unable to submit your post. Please check your connection and try again.'],
        };
      }
    },
    textareaKeydown(e: KeyboardEvent) {
      if (e.key === 'Enter' && window.app.keyboardType === 'physical') {
        e.preventDefault();
        this.submitForm();
      }
    },
    triggerFileInputClick() {
      (this.$refs.fileInput as HTMLInputElement).click();
    },
  },
});
