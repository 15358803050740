
import linkify from 'linkifyjs/html';
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent, nextTick, PropType } from 'vue';
import { mapState } from 'vuex';
import { ClockIcon, Trash2Icon } from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { CommentInterface, PostInterface } from '@/interfaces/posts';
import { UserRoleAndAccessInterface } from '@/interfaces/teams';
import Attachments from './Attachments.vue';
import Comment from './Comment.vue';
import CommentForm from './CommentForm.vue';
import Like from './Like.vue';

export default defineComponent({
  components: {
    ClockIcon,
    Trash2Icon,
    ProfileImage,
    Attachments,
    Comment,
    CommentForm,
    Like,
  },
  props: {
    post: {
      type: Object as PropType<PostInterface>,
      required: true,
    },
    teamSlug: {
      type: String,
      required: false,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    teamUserRoleAndAccess: {
      type: Object as PropType<UserRoleAndAccessInterface>,
      required: false,
    },
  },
  emits: [
    'postDeleted',
  ],
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    postType: 'user' as 'user' | 'team',
    audienceMap: {
      p: 'Public',
      f: 'Followers',
      mf: 'Mutual Followers',
    },
    deletionInProgress: false,
    comments: [] as Array<CommentInterface>,
    firstTopLevelComment: 0,
    lastTopLevelComment: 0,
    startDepth: 0,
    singleThread: null as null | number,
    activeComment: null as null | number,
    hiddenComments: [] as Array<number>,
    commentsVisible: true,
    viewerOptions: {
      filter(image: HTMLImageElement) {
        return image.dataset.fullSrc !== undefined;
      },
      url(image: HTMLImageElement) {
        return image.dataset.fullSrc;
      },
    },
  }),
  computed: {
    ...mapState([
      'timestampKeySuffix',
    ]),
    commentsToShow(): Array<CommentInterface> {
      if (this.singleThread === null) {
        return this.comments.filter((c: CommentInterface) => c.path.length <= 5);
      }

      const comments = this.comments.filter(
        // eslint-disable-next-line max-len
        (c: CommentInterface) => c.path.includes(this.singleThread as number) && c.path.length - this.startDepth <= 5,
      );

      if (comments.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const comment of this.comments) {
          if (comment.id === comments[0].path[comments[0].path.length - 2]) {
            comments.unshift(comment);
            break;
          }
        }
      }

      return comments;
    },
    postHtml(): string {
      return linkify(
        this.linebreaksbr(this.post.body),
        {
          defaultProtocol: 'https',
        },
      );
    },
  },
  created() {
    if (this.teamUserRoleAndAccess !== undefined && this.teamUserRoleAndAccess.role !== undefined) {
      this.postType = 'team';
    }

    if (this.post.comments.length) {
      this.comments = this.post.comments;
      const topLevelComments = this.comments.filter((c: CommentInterface) => c.path.length === 1);
      this.firstTopLevelComment = topLevelComments[0].id;
      this.lastTopLevelComment = topLevelComments[topLevelComments.length - 1].id;
    }
  },
  methods: {
    deletePost() {
      this.$swal.fire({
        title: 'Are You Sure?',
        text: 'Do you really want to delete this post?',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete It',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.deletionInProgress = true;

          const responseData = await this.api({
            url: `posts/${this.post.id}/`,
            method: 'DELETE',
          });

          this.deletionInProgress = false;

          if (responseData.status === 204) {
            this.$emit('postDeleted');
          } else {
            const title = 'Failed to Delete This Post';
            let text;

            if (responseData.status === 404) {
              text = 'This post no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
    commentClicked(comment: CommentInterface) {
      if (this.activeComment !== comment.id) {
        this.activeComment = comment.id;
      } else {
        this.activeComment = null;
      }
    },
    commentCreated(newComment: CommentInterface) {
      // We do not want the comment to say that it was posted in the future
      // (i.e. "in less than a minute")

      // eslint-disable-next-line no-param-reassign
      newComment.created = new Date(Date.now() - 2000).toISOString();
      // eslint-disable-next-line no-param-reassign
      newComment.has_children = false;

      if (newComment.path.length === 1) {
        // This is a top-level comment, so add it to the bottom.

        this.comments = this.comments.concat([newComment]);
        this.lastTopLevelComment = newComment.id;

        if (this.firstTopLevelComment === 0) {
          this.firstTopLevelComment = newComment.id;
        }
      } else {
        // This is a reply, so add it in the correct spot.

        const newPath = `${newComment.path.slice(0, -1).join('-')}-`;
        let insertionIndex = 0;
        let found = false;

        // eslint-disable-next-line no-restricted-syntax
        for (const comment of this.comments) {
          const path = `${comment.path.join('-')}-`;

          if (path.startsWith(newPath)) {
            comment.has_children = true;
            found = true;
          } else if (found) {
            break;
          }

          insertionIndex += 1;
        }

        this.comments.splice(insertionIndex, 0, newComment);
      }

      nextTick(() => {
        const commentElem = document.querySelector(`.js-comment[data-id="${newComment.id}"]`) as HTMLDivElement;

        if (commentElem) {
          this.scrollToComment(commentElem);
          this.activeComment = newComment.id;
        }
      });
    },
    commentDeleted(deletedCommentId: number) {
      const changeActiveComment = 0;
      const comments = [] as Array<CommentInterface>;

      this.comments.forEach((comment) => {
        if (comment.path.includes(deletedCommentId)) {
          if (comment.id === this.activeComment) {
            this.activeComment = null;
          }

          if (comment.id === this.singleThread) {
            const currentRoute = this.$router.currentRoute.value;

            if (currentRoute.name === 'user_comment') {
              this.$router.push({
                name: 'user_post',
                params: {
                  username: this.post.user.username as string,
                  postId: this.post.id,
                },
              });
            } else if (currentRoute.name === 'team_comment') {
              this.$router.push({
                name: 'team_post',
                params: {
                  teamSlug: this.teamSlug,
                  postId: this.post.id,
                },
              });
            }
          }
        } else {
          comments.push(comment);
        }
      });

      this.comments = comments;

      const topLevelComments = comments.filter((c) => c.path.length === 1);

      if (topLevelComments.length) {
        this.firstTopLevelComment = topLevelComments[0].id;
        this.lastTopLevelComment = topLevelComments[topLevelComments.length - 1].id;
      } else {
        this.firstTopLevelComment = 0;
        this.lastTopLevelComment = 0;
      }

      if (changeActiveComment) {
        this.activeComment = changeActiveComment;
      }
    },
    getNextSibling(elem: HTMLDivElement, selector: string) {
      let sibling = elem.nextElementSibling;

      if (!selector) {
        return sibling;
      }

      while (sibling) {
        if (sibling.matches(selector)) {
          break;
        }

        sibling = sibling.nextElementSibling;
      }

      return sibling;
    },
    getPreviousSibling(elem: HTMLDivElement, selector: string) {
      let sibling = elem.previousElementSibling;

      if (!selector) {
        return sibling;
      }

      while (sibling) {
        if (sibling.matches(selector)) {
          break;
        }

        sibling = sibling.previousElementSibling;
      }

      return sibling;
    },
    navigateToCommentByDirection(comment: HTMLDivElement, direction: 'prev' | 'next') {
      if (direction === 'next') {
        const nextCommentElem = this.getNextSibling(comment, '.comment-level-1') as HTMLDivElement;

        if (nextCommentElem) {
          this.activeComment = parseInt(nextCommentElem.dataset.id as string, 10);
          this.scrollToComment(nextCommentElem);
        }
      } else {
        const prevCommentElem = this.getPreviousSibling(comment, '.comment-level-1') as HTMLDivElement;

        if (prevCommentElem) {
          this.activeComment = parseInt(prevCommentElem.dataset.id as string, 10);
          this.scrollToComment(prevCommentElem);
        }
      }
    },
    navigateToCommentById(id: number) {
      const commentElem = document.querySelector(`.js-comment[data-id="${id}"]`);

      if (commentElem) {
        this.activeComment = id;
        this.scrollToComment(commentElem as HTMLDivElement);
      } else {
        const comment = this.comments.filter((c: CommentInterface) => c.id === id)[0];
        this.viewSingleThread(comment);
      }
    },
    scrollToComment(commentElem: HTMLDivElement) {
      const commentsElem = this.$refs.comments as HTMLDivElement;
      const commentsBounding = commentsElem.getBoundingClientRect();
      const headerHeight = parseInt(
        getComputedStyle(document.documentElement).getPropertyValue('--is-authenticated-header-height'),
        10,
      );

      if (commentsBounding.top - headerHeight < 0) {
        window.scrollTo({
          top: commentsElem.offsetTop - headerHeight,
          behavior: 'smooth',
        });
      }

      commentsElem.scrollTo({
        top: commentElem.offsetTop,
        behavior: 'smooth',
      });
    },
    timestampLink() {
      if (this.postType === 'user') {
        return {
          name: 'user_post',
          params: {
            username: this.post.user.username,
            postId: this.post.id,
          },
        };
      }

      return {
        name: 'team_post',
        params: {
          teamSlug: this.teamSlug,
          postId: this.post.id,
        },
      };
    },
    toggleCommentHidden(id: number) {
      if (this.hiddenComments.includes(id)) {
        this.hiddenComments = this.hiddenComments.filter((i) => i !== id);
      } else {
        this.hiddenComments.push(id);
      }
    },
    viewAllComments() {
      this.startDepth = 0;
      this.singleThread = null;
    },
    viewSingleThread(comment: CommentInterface) {
      let startDepth = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const c of this.comments) {
        if (c.id === comment.id) {
          startDepth = comment.path.length - 2;
          break;
        }
      }

      if (startDepth >= 1) {
        this.startDepth = startDepth;
        this.singleThread = comment.id;
      } else {
        this.startDepth = 0;
        this.singleThread = null;
      }

      this.activeComment = comment.id;

      nextTick(() => {
        this.scrollToComment(
          document.querySelector(`.js-comment[data-id="${comment.id}"]`) as HTMLDivElement,
        );
      });
    },
  },
});
