
import { defineComponent } from 'vue';
import { AttachmentImageInterface, AttachmentVideoInterface } from '@/interfaces/posts';

export default defineComponent({
  props: {
    attachments: {
      type: Array as () => Array<AttachmentImageInterface | AttachmentVideoInterface>,
      required: true,
    },
  },
  data() {
    const imgs = [] as Array<{ src: string }>;

    this.attachments.forEach((attachment) => {
      if (attachment.type === 'image') {
        imgs.push({
          src: attachment.url.full,
        });
      }
    });

    return {
      visible: false,
      imgs,
      index: 0,
    };
  },
  methods: {
    imageLinkClick(url: string) {
      let index = 0;

      this.imgs.forEach((img, imgIndex) => {
        if (img.src === url) {
          index = imgIndex;
        }
      });

      this.index = index;
      this.visible = true;
    },
  },
});
