
import linkify from 'linkifyjs/html';
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent, nextTick, PropType } from 'vue';
import { mapState } from 'vuex';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ClockIcon,
  Trash2Icon,
} from '@zhuowenli/vue-feather-icons';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { CommentInterface } from '@/interfaces/posts';
import { UserRoleAndAccessInterface } from '@/interfaces/teams';
import Attachment from './Attachment.vue';
import CommentForm from './CommentForm.vue';
import Like from './Like.vue';

export default defineComponent({
  components: {
    ArrowDownIcon,
    ArrowUpIcon,
    ClockIcon,
    Trash2Icon,
    ProfileImage,
    Attachment,
    CommentForm,
    Like,
  },
  props: {
    comment: {
      type: Object as PropType<CommentInterface>,
      required: true,
    },
    firstTopLevelComment: {
      type: Number,
      required: true,
    },
    lastTopLevelComment: {
      type: Number,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    postId: {
      type: Number,
      required: true,
    },
    postAuthor: {
      type: String,
      required: false,
      default: '',
    },
    teamSlug: {
      type: String,
      required: false,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    teamUserRoleAndAccess: {
      type: Object as PropType<UserRoleAndAccessInterface>,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: true,
    },
    hiddenComments: {
      type: Array as () => Array<number>,
      required: false,
      default() {
        return [];
      },
    },
  },
  emits: [
    'commentClicked',
    'commentShown',
    'commentHidden',
    'commentDeleted',
    'readMoreClicked',
    'replyCreated',
    'prevCommentButtonClicked',
    'nextCommentButtonClicked',
    'rootCommentButtonClicked',
    'parentCommentButtonClicked',
  ],
  data: () => ({
    commentType: 'user' as 'user' | 'team',
    hidden: false,
    showReplyForm: false,
    deletionInProgress: false,
  }),
  computed: {
    ...mapState([
      'timestampKeySuffix',
    ]),
    visible(): boolean {
      // eslint-disable-next-line no-restricted-syntax
      for (const id of this.hiddenComments) {
        if (this.comment.id !== id && this.comment.path.includes(id)) {
          return false;
        }
      }

      return true;
    },
  },
  created() {
    if (this.teamUserRoleAndAccess !== undefined && this.teamUserRoleAndAccess.role !== undefined) {
      this.commentType = 'team';
    }
  },
  methods: {
    commentClicked() {
      if (this.hidden) {
        this.hidden = false;
        this.$emit('commentShown');
      } else {
        this.$emit('commentClicked');
      }
    },
    commentHtml() {
      return linkify(
        this.linebreaksbr(this.comment.body),
        {
          defaultProtocol: 'https',
        },
      );
    },
    hideComment() {
      this.hidden = true;
      this.$emit('commentHidden');
    },
    replyClicked() {
      this.showReplyForm = true;

      nextTick(() => {
        (this.$refs.commentForm as InstanceType<typeof CommentForm>).focusTextarea();
      });
    },
    replyCreated(newComment: CommentInterface) {
      this.showReplyForm = false;
      this.$emit('replyCreated', newComment);
    },
    timestampLink() {
      if (this.commentType === 'user') {
        return {
          name: 'user_comment',
          params: {
            username: this.postAuthor,
            postId: this.postId,
            commentId: this.comment.id,
          },
        };
      }

      return {
        name: 'team_comment',
        params: {
          teamSlug: this.teamSlug,
          postId: this.postId,
          commentId: this.comment.id,
        },
      };
    },
    deleteComment() {
      this.$swal.fire({
        title: 'Are You Sure?',
        text: 'Do you really want to delete this comment?',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete It',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.deletionInProgress = true;

          const responseData = await this.api({
            url: `comments/${this.comment.id}/`,
            method: 'DELETE',
          });

          this.deletionInProgress = false;

          if (responseData.status === 204) {
            this.$emit('commentDeleted');
          } else {
            const title = 'Failed to Delete This Comment';
            let text;

            if (responseData.status === 404) {
              text = 'This comment no longer exists.';
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }
        }
      });
    },
  },
});
