<template>
  <div class="component">
    <transition-slide mode="out-in">
      <div
        v-if="formShowing === false"
        class="text-center"
      >
        <button
          type="button"
          class="btn btn-outline-primary btn-sm"
          @click="formShowing = true"
        >
          Create a Post
        </button>
      </div>

      <form
        v-else
        class="post-form"
        @submit.prevent="submitForm"
      >
        <alert
          v-if="formErrors.non_field_errors !== undefined"
          variant="danger"
          class="js-form-error"
          dismissible
          @dismissed="formErrors = {}"
        >
          {{ formErrors.non_field_errors[0] }}
        </alert>

        <div class="profile-image-and-audience">
          <profile-image :size="32" />

          <template v-if="teamSlug === ''">
            <button
              v-tooltip="'Change audience'"
              type="button"
              class="btn btn-outline-primary btn-sm"
              @click="audienceModalShowing = true"
            >
              Audience: {{ audienceMap[audience] }}
            </button>

            <modal
              ref="audienceModal"
              :show="audienceModalShowing"
              class="audience-modal"
              @modalClosed="audienceModalShowing = false"
            >
              <template #header>
                <h2>Choose Audience</h2>
              </template>

              <div class="horizontal-padding">
                <p class="text-center">
                  Who should be able to see this post?
                </p>

                <button
                  type="button"
                  :class="`btn ${audience === 'p' ? 'btn-primary' : 'btn-outline-primary'}`"
                  :aria-pressed="audience === 'p'"
                  @click="setAudience('p')"
                >
                  Public
                </button>

                <button
                  type="button"
                  :class="`btn ${audience === 'f' ? 'btn-primary' : 'btn-outline-primary'}`"
                  :aria-pressed="audience === 'f'"
                  @click="setAudience('f')"
                >
                  Followers
                </button>

                <button
                  type="button"
                  :class="`btn ${audience === 'mf' ? 'btn-primary' : 'btn-outline-primary'}`"
                  :aria-pressed="audience === 'mf'"
                  @click="setAudience('mf')"
                >
                  Mutual Followers
                </button>
              </div>
            </modal>
          </template>
        </div>

        <div class="textarea-and-buttons">
          <textarea
            ref="body"
            v-model="body"
            placeholder="Write a message"
            :disabled="formSubmitting"
            @keydown="textareaKeydown"
            @keyup="updateTextareaHeight"
            @paste="updateTextareaHeight"
          />

          <div class="buttons">
            <button
              v-tooltip="'Attach a file'"
              aria-label="Attach a file"
              type="button"
              class="btn btn-outline-light btn-sm"
              :disabled="attachments.length >= 5"
              @click="triggerFileInputClick"
            >
              <paperclip-icon />
            </button>

            <button
              v-tooltip="'Submit post'"
              aria-label="Submit post"
              type="submit"
              class="btn btn-primary btn-sm"
              :disabled="formSubmitting"
            >
              <spinner v-if="formSubmitting" />

              <send-icon v-else />
            </button>
          </div>
        </div>

        <input
          ref="fileInput"
          :key="`file${fileInputKeySuffix}`"
          type="file"
          accept="image/gif, image/jpeg, image/png, video/mp4, video/quicktime, video/x-m4v"
          tabindex="-1"
          aria-hidden="true"
          @change="attachFile"
        >

        <div
          v-if="attachments.length"
          class="attachments"
        >
          <attachment-upload
            v-for="attachment of attachments"
            :key="`attachment${attachment.key}`"
            :attachment="attachment"
            @attachmentRemoved="removeAttachment(attachment)"
          />
        </div>
      </form>
    </transition-slide>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue';
import { PaperclipIcon, SendIcon } from '@zhuowenli/vue-feather-icons';
import AttachmentUpload from '@/components/AttachmentUpload.vue';
import Modal from '@/components/Modal.vue';
import TransitionSlide from '@/components/TransitionSlide.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { NewAttachmentInterface } from '@/interfaces/posts';

export default defineComponent({
  components: {
    PaperclipIcon,
    SendIcon,
    AttachmentUpload,
    Modal,
    TransitionSlide,
    ProfileImage,
  },
  props: {
    teamSlug: {
      type: String,
      required: true,
    },
  },
  emits: [
    'postCreated',
  ],
  data: () => ({
    formShowing: false,
    audienceModalShowing: false,
    audienceMap: {
      p: 'Public',
      f: 'Followers',
      mf: 'Mutual Followers',
    },
    audience: 'mf' as 'p' | 'f' | 'mf',
    body: '',
    attachments: [] as Array<NewAttachmentInterface>,
    fileInputKeySuffix: 0,
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
  }),
  watch: {
    formErrors() {
      if (this.formErrors.non_field_errors !== undefined) {
        nextTick(this.scrollToFirstError);
      }
    },
  },
  methods: {
    attachFile(e: Event) {
      this.fileInputKeySuffix += 1;
      const fileInput = e.target as HTMLInputElement;
      const file = (fileInput.files as FileList)[0];

      if (file) {
        if (file.size <= process.env.VUE_APP_MAX_ATTACHMENT_BYTES) {
          const reader = new FileReader();

          reader.onload = () => {
            this.attachments.push({
              key: Date.now(),
              status: 'Idle',
              file,
            });
          };

          reader.readAsDataURL(file);
        } else {
          this.formErrors = {
            non_field_errors: ['That file is too large to upload.'],
          };
        }
      }
    },
    removeAttachment(attachment: NewAttachmentInterface) {
      this.attachments = this.attachments.filter((a) => a !== attachment);
    },
    focusTextarea() {
      const body = this.$refs.body as HTMLTextAreaElement;
      body.focus();
    },
    setAudience(audience: 'p' | 'f' | 'mf') {
      this.audience = audience;
      (this.$refs.audienceModal as InstanceType<typeof Modal>).closeModal();
    },
    async submitForm() {
      this.formErrors = {};

      if (this.body.trim() === '' && this.attachments.length === 0) {
        this.formErrors = {
          non_field_errors: ['Please write a message and/or attach a file.'],
        };
        return;
      }

      const unfinishedAttachments = {
        uploading: this.attachments.filter((a) => ['Idle', 'Uploading'].includes(a.status)).length,
        error: this.attachments.filter((a) => a.status === 'Error').length,
      };

      if (unfinishedAttachments.uploading || unfinishedAttachments.error) {
        let message = '';

        if (unfinishedAttachments.uploading) {
          message = `${unfinishedAttachments.uploading} file`;

          if (unfinishedAttachments.uploading === 1) {
            message += ' is';
          } else {
            message += 's are';
          }

          message += ' still uploading.';

          if (unfinishedAttachments.error) {
            message += ' In addition, ';
          }
        }

        if (unfinishedAttachments.error) {
          message = `${unfinishedAttachments.error} file`;

          if (unfinishedAttachments.error > 1) {
            message += 's';
          }

          message += ' failed to upload. Please either remove ';

          if (unfinishedAttachments.error === 1) {
            message += 'it';
          } else {
            message += 'them';
          }

          message += ' or try uploading ';

          if (unfinishedAttachments.error === 1) {
            message += 'it';
          } else {
            message += 'them';
          }

          message += ' again.';
        }

        this.formErrors = {
          non_field_errors: [message],
        };
        return;
      }

      this.formSubmitting = true;

      const data = {
        body: this.body,
      } as {
        body: string;
        // eslint-disable-next-line camelcase
        attachment_ids?: Array<number>;
        audience?: string;
        team?: string;
      };

      if (this.teamSlug === '') {
        data.audience = this.audience;
      } else {
        data.team = this.teamSlug;
      }

      if (this.attachments.length) {
        const attachmentIds = [] as Array<number>;

        this.attachments.forEach((attachment) => {
          attachmentIds.push(attachment.id as number);
        });

        data.attachment_ids = attachmentIds;
      }

      const responseData = await this.api({
        url: 'posts/',
        method: 'POST',
        json: data,
      });

      this.formSubmitting = false;

      if (responseData.status === 201) {
        this.body = '';
        this.attachments = [];
        this.$emit('postCreated', responseData.body);
      } else if (responseData.status === 400) {
        if (
          typeof responseData.body === 'object'
          && responseData.body.team !== undefined
        ) {
          this.formErrors = {
            non_field_errors: responseData.body.team,
          };
        } else {
          this.formErrors = responseData.body;
        }
      } else {
        this.formErrors = {
          non_field_errors: ['Unable to submit your post. Please check your connection and try again.'],
        };
      }
    },
    textareaKeydown(e: KeyboardEvent) {
      if (e.key === 'Enter' && window.app.keyboardType === 'physical') {
        e.preventDefault();
        this.submitForm();
      }
    },
    triggerFileInputClick() {
      (this.$refs.fileInput as HTMLInputElement).click();
    },
  },
});
</script>

<style lang="scss" scoped>
  .component {
    position: relative;
    margin-bottom: 1.5rem;
  }

  .post-form {
    margin-top: 1rem;
  }

  .profile-image-and-audience {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    > .btn {
      margin-left: 0.5rem;
    }
  }

  .audience-modal .btn {
    display: block;
    width: 100%;

    + .btn {
      margin-top: 2rem;
      margin-left: 0;
    }
  }

  .textarea-and-buttons {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr auto;
  }

  .buttons {
    display: flex;
    flex-direction: column;

    .btn + .btn {
      margin-top: 0.5rem;
      margin-left: 0;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 10px;
    opacity: 0;
    pointer-events: none;
    -webkit-tap-highlight-color: transparent;
  }

  .attachments {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(3, 1fr);
    align-items: end;
    margin-top: 1.5rem;
  }
</style>
