<template>
  <div
    class="attachment"
    :class="{ 'fixed-size': fixedSize }"
  >
    <img
      v-if="attachment.type === 'image'"
      :src="attachment.url.thumbnail"
      :data-full-src="attachment.url.full"
      :class="{ 'img-fluid': !fixedSize }"
      alt
    >

    <video
      v-else-if="attachment.type === 'video'"
      :src="`${attachment.url}#t=0.1`"
      :controls="clickAction !== 'nothing'"
      playsinline
    />

    <div
      v-if="
        (attachment.type === 'image' && clickAction !== 'normal')
          || (attachment.type === 'video' && clickAction === 'nothing')
      "
      class="attachment-cover"
      :class="{ 'attachment-cover-video': attachment.type === 'video' }"
      @click="attachmentCoverClicked"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AttachmentImageInterface, AttachmentVideoInterface } from '@/interfaces/posts';

export default defineComponent({
  props: {
    attachment: {
      type: Object as PropType<AttachmentImageInterface | AttachmentVideoInterface>,
      required: true,
    },
    clickAction: {
      type: String,
      required: false,
      default: 'normal',
      validator: (value: string) => [
        'normal',
        'nothing',
      ].includes(value) || value.startsWith('goToPost'),
    },
    fixedSize: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: [
    'attachmentClicked',
  ],
  methods: {
    attachmentCoverClicked() {
      if (this.clickAction.startsWith('goToPost')) {
        this.$router.push({
          name: 'post',
          params: {
            postId: parseInt(this.clickAction.substring(8), 10),
          },
        });
      } else if (this.clickAction === 'nothing') {
        this.$emit('attachmentClicked');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .attachment {
    position: relative;
  }

  .fixed-size {
    width: 260px;
    height: 260px;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .attachment-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .attachment-cover-video:after {
    content: 'Video';
    position: absolute;
    bottom: 3px;
    padding: 0.25em;
    width: 100%;
    height: 1.5em;
    font-size: #{'max(1vw, 0.7rem)'};
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
  }

  video {
    width: 100%;
    height: auto;
  }
</style>
