<template>
  <div class="like-content">
    <button
      type="button"
      class="btn-transparent"
      :class="{ toggled: toggled }"
      :aria-label="
        liked
          ?
            `Remove your like from this ${postOrComment}`
          :
            `Add a like to this ${postOrComment}`
      "
      @click="toggleLike"
    >
      <mdicon
        v-if="!liked"
        name="thumb-up-outline"
        size="16"
        class="not-liked"
      />

      <mdicon
        v-else
        name="thumb-up"
        size="16"
        class="liked"
      />

      <spinner
        v-if="showSpinner"
        :size="24"
      />
    </button>

    <button
      v-tooltip="`See who has liked this ${postOrComment}`"
      type="button"
      class="btn-transparent show-users-button"
      :aria-label="`Total likes: ${totalLikes}`"
      @click="showUsersModal"
    >
      {{ totalLikes }}
    </button>

    <modal
      :show="usersModalShowing"
      @modalClosed="usersModalShowing = false"
    >
      <template #header>
        <h2>Likes</h2>
      </template>

      <template v-if="userListStatus === 'loaded'">
        <div
          v-if="userList.results.length"
          class="user-list"
        >
          <router-link
            v-for="user of userList.results"
            :key="`user${user.username}`"
            :to="{ name: 'user_profile', params: { username: user.username } }"
          >
            <profile-image :user="user" />
            {{ user.first_name }} {{ user.last_name }}
          </router-link>
        </div>

        <div
          v-else
          class="horizontal-padding no-likes-content"
        >
          This {{ postOrComment }} doesn't have any likes yet.
        </div>
      </template>

      <spinner
        v-else-if="userListStatus === 'loading'"
        preset="large"
      />

      <div
        v-else-if="userListStatus === 'error'"
        class="horizontal-padding"
      >
        <alert variant="danger">
          An error occurred while trying to load the list of users. Please
          check your connection and try again.
        </alert>

        <button
          type="button"
          class="btn btn-outline-primary"
          @click="loadUserList"
        >
          Try Again
        </button>
      </div>

      <div
        v-else-if="userListStatus === 'not_found'"
        class="horizontal-padding"
      >
        <alert variant="danger">
          The {{ postOrComment }} no longer exists.
        </alert>
      </div>
    </modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Modal from '@/components/Modal.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { CommentInterface, PostInterface } from '@/interfaces/posts';
import { UserInterface } from '@/interfaces/users';

export default defineComponent({
  components: {
    Modal,
    ProfileImage,
  },
  props: {
    post: {
      type: Object as PropType<PostInterface>,
      required: false,
      default: undefined,
    },
    comment: {
      type: Object as PropType<CommentInterface>,
      required: false,
      default: undefined,
    },
  },
  data: () => ({
    postOrComment: '',
    liked: false,
    totalLikes: 0,
    toggled: false,
    toggling: false,
    showSpinner: false,
    usersModalShowing: false,
    userListStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'not_found' | 'error',
    userList: {} as {
      next: string | null,
      previous: string | null,
      results: Array<UserInterface>,
    },
  }),
  created() {
    if (this.post !== undefined) {
      this.postOrComment = 'post';
      this.liked = this.post.liked_by_user;
      this.totalLikes = this.post.total_likes;
    } else if (this.comment !== undefined) {
      this.postOrComment = 'comment';
      this.liked = this.comment.liked_by_user;
      this.totalLikes = this.comment.total_likes;
    }
  },
  methods: {
    showUsersModal() {
      this.usersModalShowing = true;
      this.loadUserList();
    },
    async loadUserList() {
      if (this.userListStatus === 'loading') {
        return;
      }

      this.userListStatus = 'loading';

      let url = '';

      if (this.post !== undefined) {
        url = `posts/${this.post.id}/likes/?page_size=100`;
      } else if (this.comment !== undefined) {
        url = `comments/${this.comment.id}/likes/?page_size=100`;
      }

      const responseData = await this.api({
        url,
      });

      if (responseData.status === 200) {
        this.userList = responseData.body;
        this.userListStatus = 'loaded';
      } else if (responseData.status === 404) {
        this.userListStatus = 'not_found';
      } else {
        this.userListStatus = 'error';
      }
    },
    async toggleLike() {
      if (!this.toggling) {
        this.toggling = true;

        const showSpinnerTimeoutID = setTimeout(() => {
          this.showSpinner = true;
        }, 100);

        let url = '';

        if (this.post !== undefined) {
          url = `posts/${this.post.id}/toggle_like/`;
        } else if (this.comment !== undefined) {
          url = `comments/${this.comment.id}/toggle_like/`;
        }

        const responseData = await this.api({
          url,
          method: 'POST',
          json: { like: !this.liked },
        });

        clearTimeout(showSpinnerTimeoutID);
        this.toggling = false;
        this.showSpinner = false;

        if (responseData.status === 200) {
          this.liked = !this.liked;

          if (this.liked) {
            this.totalLikes += 1;
          } else {
            this.totalLikes -= 1;
          }

          this.toggled = false;

          setTimeout(() => {
            this.toggled = true;
          }, 50);
        } else {
          setTimeout(() => {
            const likeOrUnlike = this.liked ? 'Unlike' : 'Like';
            const title = `Failed to ${likeOrUnlike} This ${this.capitalizeWords(this.postOrComment)}`;
            let text;

            if (responseData.status === 404) {
              text = `This ${this.postOrComment} no longer exists.`;
            } else {
              text = 'Please check your connection and try again.';
            }

            this.$swal(title, text);
          }, 50);
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .like-content {
    display: inline-grid;
    grid-gap: 0.5rem;
    grid-template-columns: 24px auto;
    align-items: center;
  }

  .btn-transparent {
    position: relative;
    display: inline-block;
    -webkit-tap-highlight-color: transparent;

    &:focus:not(:focus-visible) {
      outline: 0;
    }

    .spinner {
      position: absolute;
      left: 0;
    }
  }

  .not-liked {
    color: #fff;
  }

  .liked {
    color: var(--blue);
  }

  .toggled {
    animation-name: scale;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  @keyframes scale {
    0%,
    100% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.2);
    }
  }

  .show-users-button {
    color: #fff;
    font-weight: 500;
  }

  .modal {
    padding-bottom: 0;
  }

  .user-list a {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 9px 18px;
    border-bottom: 1px solid var(--gray);

    &:focus,
    &:hover {
      background-color: var(--gray-dark)
    }

    &:focus {
      outline: 0;
    }
  }

  .no-likes-content {
    color: #fff;
    text-align: center;
  }
</style>
